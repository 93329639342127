{ /* Noto Sans  */
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../src/assets/font/NotoSans-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../src/assets/font/NotoSans-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../src/assets/font/NotoSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/font/NotoSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../src/assets/font/NotoSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../src/assets/font/NotoSans-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../src/assets/font/NotoSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../src/assets/font/NotoSans-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../src/assets/font/NotoSans-Black.woff2') format('woff2');
}
